import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getPhoneIsShowHeader = createAsyncThunk("fetchcData", (payload, { dispatch }) => {
    dispatch(changePhoneIsShowHeader(payload))
})

export const getThemeStatus=createAsyncThunk("themeStatus",(payload,{dispatch})=>{
    dispatch(changeThemeStatus(payload))
})

export const getIsShowLoading=createAsyncThunk("isShowLoading",(payload,{dispatch})=>{
    dispatch(changeThemeStatus(payload))
})

export const getchangeblogType=createAsyncThunk("changeblogType",(payload,{dispatch})=>{
    dispatch(changeblogType(payload))
})

const globalSlice = createSlice({
    name: 'detail',
    initialState: {
        // phone header status
        isShowPhoneHeader: false,
        // dark or light theme status
        themeStatus: true,
        // is show loading icon
        isLoading: true,
        // blog type index
        blogType: 1,
    },
    reducers: {
        changePhoneIsShowHeader(state, { payload }) {
            state.isShowPhoneHeader = payload
        },
        changeThemeStatus(state, { payload }) {
            state.themeStatus = payload
        },
        changeIsShowLoading(state,{payload}){
            state.isLoading=payload
        },
        changeblogType(state,{payload}){
            state.blogType=payload
        }
    }

})

export const {
    changePhoneIsShowHeader,
    changeThemeStatus,
    changeIsShowLoading,
    changeblogType
} = globalSlice.actions

export default globalSlice.reducer;