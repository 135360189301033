import React, { memo } from 'react'
import GitHubIcon from '@mui/icons-material/GitHub';

import { LeftWrapper } from './style'
import logo from '@/assets/images/logo.jpg'
import SearchUI from '@/base-ui/search'
import IconJueJin from '@/assets/images/svg/juejin'

export default memo(function HeaderLeft() {
  return (
    <LeftWrapper>
      <div className="logo" style={{ flex: 1 }}>
        <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span style={{ marginRight: '20px',whiteSpace: 'nowrap',display:'flex' ,justifyContent:'center',alignItems:'center'}}>
            <a style={{display:'flex' ,justifyContent:'center',alignItems:'center', textDecoration: 'none', color: 'inherit' }} href="/"  rel="noreferrer">
              <img style={{width:'25px',height:'25px',margin:'0 10px',borderRadius:'13%'}} src={logo} alt="" />
              YaBo前端
            </a>
          </span>
          <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://github.com/dahaisyb" target="_blank" rel="noreferrer">
            <GitHubIcon />
          </a>
          <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://juejin.cn/user/4020277027286760" target="_blank" rel="noreferrer">
            <IconJueJin />
          </a>
        </span>
      </div>
      <SearchUI />
    </LeftWrapper>
  )
})
