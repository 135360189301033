import styled from "styled-components"

export const AppFooterWrapper=styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    border-top: 1px solid ${props=>props.theme.cardBgColor};
    margin-top: 20px;
  .text {

  }
`