import React, { memo } from 'react'
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import {SearchWrapper} from './style'
const SearchIconWrapper = styled.div`
margin: 5px 5px 0 5px;
`

const Search = styled.div`
  flex:2;
  display: flex;
  align-items: center;
  background-color: ${(props)=>props.theme.searchBgColor};
  border-radius: 8px;
  width: 150px;
  height: 36px;
  &:hover {
    border: 1px solid #63DFED;
  }
`

const StyledInputBase = styled.input`
  width: 100%;
  border: none;
  height: 100%;
  border-radius: 8px;
  background-color: ${(props)=>props.theme.searchBgColor};
  color: ${(props)=>props.theme.textColor};
  &:focus {
    outline: none;
  }
`

export default memo(function SearchUI() {
  return (
    <SearchWrapper>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          onBlur={(e) => console.log(e.target.value)}
          placeholder="Search…"
        />
      </Search>
    </SearchWrapper>
  )
})
