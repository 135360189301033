// themes.js
export const lightTheme = {
  primaryColor: '#FFFFFF',
  textColor: '#3c3c43',
  searchBgColor: '#F6F6F7',
  loadingBgClogor: 'rgba(255,255,255,.5)',
  // 其他主题相关的颜色和样式
  // card颜色
  cardBgColor: '#F6F6F7',
  MomentcardBgColor: '#F6F6F7',
  // 样式组
  customStyles: {
    fontColor: {
      background: '-webkit-linear-gradient(#0093E9, #80D0C7)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      color: '#0093E9',
      // background: 'linear-gradient(160deg, #0093E9 0%, #80D0C7 99%)',
      background: 'linear-gradient(160deg, #0093E9 45%, #80D0C7 66%)'
    },
  }
};

export const darkTheme = {
  primaryColor: '#1E1E20',
  textColor: '#FFFFF5DB',
  searchBgColor: '#161618',
  loadingBgClogor: 'rgba(0,0,0,.5)',
  // 其他主题相关的颜色和样式
  // card颜色
  cardBgColor: '#252529',
  MomentcardBgColor: '#44444c',
    // 样式组
    customStyles: {
      fontColor: {
          /* 字体渐变色 */
          background: '-webkit-linear-gradient(#0093E9, #80D0C7)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          color: '#0093E9',
          // background: 'linear-gradient(160deg, #0093E9 0%, #80D0C7 99%)',
          background: 'linear-gradient(160deg, #0093E9 45%, #80D0C7 66%)'
      },
    }
};