import React from 'react'
import { Navigate } from 'react-router-dom'
const Home = React.lazy(() => import('@/views/home'))
const Detail = React.lazy(() => import('@/views/detail'))
const Life = React.lazy(() => import('@/views/life'))
const Blob = React.lazy(() => import('@/views/blog'))
const LifeDetail = React.lazy(() => import('@/views/life-detail'))
const TravelBeat = React.lazy(() => import('@/views/travel-beat'))
const Moment = React.lazy(() => import('@/views/moment'))
const Login = React.lazy(() => import('@/views/login'))
const AboutMe = React.lazy(() => import('@/views/about-me'))
const Love = React.lazy(() => import('@/views/love'))
const LovePeople = React.lazy(() => import('@/views/love/c-cpns/people'))
const CreateLove = React.lazy(() => import('@/views/love/c-cpns/createLove'))
const RetrievePassword = React.lazy(() => import('@/views/login/c-cpns/RetrievePassword'))
const Testcss = React.lazy(() => import('@/views/testcss'))
const ChatYB = React.lazy(() => import('@/views/chat'))

const routes = [
  {
    path: '/',
    element: <Navigate to="/home" />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/blog',
    element: <Blob />
  },
  {
    path: '/detail/:id',
    element: <Detail />
  },
  {
    path: '/life',
    element: <Life/>
  },
  {
    path: '/lifedetail/:id',
    element: <LifeDetail />
  },
  {
    path:'/moment',
    element: <Moment />
  },
  {
    path:'/travelBeat',
    element: <TravelBeat />
  },
  {
    path:'/aboutme',
    element: <AboutMe />
  },
  {
    path:'/love',
    element: <Love />
  },
  {
    path: '/lovePeople/:id',
    element: <LovePeople />
  },
  {
    path: '/createlove',
    element: <CreateLove />
  },
  {
    path: '/RetrievePassword',
    element: <RetrievePassword />
  },
  {
    path: '/Testcss',
    element: <Testcss />
  },
  {
    path: '/chat/:id',
    element: <ChatYB />
  },
]

export default routes