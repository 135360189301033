import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
export const getIsShowHeader = createAsyncThunk("fetchcData", (payload, { dispatch }) => {
    dispatch(changeIsShowHeader(payload))
})

const detailSlice = createSlice({
    name: 'detail',
    // 初始化数据
    initialState: {
        isShowHeader: true
    },
    reducers: {
        changeIsShowHeader(state, { payload }) {
            state.isShowHeader = payload
        }
    }

})

export const {
    changeIsShowHeader
} = detailSlice.actions

export default detailSlice.reducer;