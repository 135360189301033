import React, { memo } from 'react'

import {AppFooterWrapper} from './style'

export default memo(function AppFooter() {
  return (
    <AppFooterWrapper>
      <div className="text">
      京ICP备2023032333号
      </div>
    </AppFooterWrapper>
  )
})
