// ThemeProvider.js
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './themes';

export const MyThemeProvider = ({ children, isDarkMode }) => {
  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      {children}
    </ThemeProvider>
  );
};