import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "./modules/home";
import detailReducer from "./modules/detail"
import globalReducer from "./modules/global"

const store = configureStore({
  reducer: {
    home: homeReducer,
    detail:detailReducer,
    global:globalReducer
  }
});
export default store;