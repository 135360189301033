import styled from "styled-components";

export const UserWrapper=styled.div`
cursor: pointer;
.ant-dropdown {
pointer-events: auto!important;
opacity: 1!important;
}
.slide-up-enter, .slide-up-appear {
animation-duration: 0s !important;
}
.menu-container {
  position: relative;
  z-index: 1000; /* 设置合适的 z-index 值 */
}
`