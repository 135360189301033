import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { HeaderWrapper } from "./style";
import HeaderLeft from "./c-cpns/header-left";
import HeaderRight from "./c-cpns/header-right";
import PhoneBar from "./c-cpns/phono-bar";
export default memo(function AppHeader({
  toggleTheme,
  isShowHeader,
  themeStatus,
}) {
  const { isLoginStatus } = useSelector(
    (state) => ({
      isLoginStatus: state.home.isLoginStatus,
    }),
    shallowEqual
  );
  const [userInfo, setUserInfo] = useState(
    JSON.parse(window.localStorage.getItem("userInfo"))
  );
  const [isshowbgc, setIsshowbgc] = useState(false);
  const [isShowHeaderBar, setIsShowHeaderBar] = useState(true);
  const [isShowPhoneBar, setIsShowPhoneBar] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      var scrollDistance = window.scrollY;
      if (scrollDistance > 0) {
        setIsshowbgc(true);
      } else {
        setIsshowbgc(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setUserInfo(JSON.parse(window.localStorage.getItem("userInfo")));
  }, [isLoginStatus]);

  // 监听屏幕宽度变化
  useEffect(() => {
    const handleResize = () => {
      var width = window.innerWidth;
      if (width <= 936) {
        setIsShowHeaderBar(false);
      } else {
        setIsShowHeaderBar(true);
      }
      if (width <= 788) {
        setIsShowPhoneBar(true);
      } else {
        setIsShowPhoneBar(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <HeaderWrapper isshowbgc={isshowbgc}>
      {!isShowPhoneBar
        ? (isShowHeaderBar || isShowHeader) && (
            <div className="box">
              <HeaderLeft></HeaderLeft>
              <HeaderRight
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                toggleTheme={toggleTheme}
                themeStatus={themeStatus}
              ></HeaderRight>
            </div>
          )
        : isShowHeader && (
            <PhoneBar
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              toggleTheme={toggleTheme}
              themeStatus={themeStatus}
            ></PhoneBar>
          )}
    </HeaderWrapper>
  );
});
