import styled from "styled-components"

export const LeftWrapper=styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  z-index: 3;
  transition: color .25s;
  color: ${(props) => props.theme.textColor};
  .logo {
    a {
      margin-right: 15px;
    }
  }
`